
import Vue from 'vue'

export default Vue.extend({
  name: 'TeamPage',
  props: {
    id: {
      type: String,
      default: ''
    }
  }
})
