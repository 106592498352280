import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VContainer,{staticClass:"flex px-0",attrs:{"fluid":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VBtn,{attrs:{"icon":"","color":"light","to":{ name: 'TeamsPage', params: { id: _vm.id } }}},[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" mdi-arrow-up-left-bold ")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$route.params.data.name))])],1),_c(VRow,[_c(VCol,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t('number'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('name'))+" ")])])]),_c('tbody',_vm._l((_vm.$store.state.ClubTeamsModule.data[_vm.$route.params.team].players),function(element,index){return _c('tr',{key:index},[_c('td',[_c('span',[_vm._v(_vm._s(element.number))])]),_c('td',[_c('span',[_vm._v(_vm._s(element.name))])])])}),0)]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }